import React from 'react';
import { FilterProps, FilterValue, IdType, Row } from 'react-table';

export function ToggleFilter({
  column: { filterValue, render, setFilter, preFilteredRows, id },
}: FilterProps<any>) {

  return (
    <input className="uk-checkbox"
      checked={filterValue}
      value={filterValue}
      onChange={(e) => { setFilter(!filterValue) }}
      type="checkbox" />
  );

}

export function toggleFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  return rows.filter((row) => !filterValue || (filterValue && row.values[id[0]]));
}

// Let the table remove the filter if the string is empty
toggleFilter.autoRemove = (val: any) => !val;
