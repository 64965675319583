import React from 'react';
import { authContext } from '../contexts/AuthContext';

export default function Index() {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  return (
    <div className="uk-margin \
          uk-margin uk-margin-left uk-margin-right" >
      <div className="uk-align-left uk-grid" >
        <div className="uk-align-left">
          <h2>
            Welcome to the User Admin tool
          </h2>
          <h3>
            {authToken ? 'Welcome Back' : 'Log in to get started'}
          </h3>
        </div>
      </div>
    </div>
  );
}
