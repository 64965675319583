import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Logout } from '../components/Logout';
import SesameLogin from '../components/SesameLogin';
import { authContext } from '../contexts/AuthContext';
import { IProfile } from '../types';
import { loadProfile } from '../util/ProfileUtil';

const UserProfile = (props: React.HTMLAttributes<HTMLElement>) => {
  //  const [profile, setProfile] = useState({});
  const auth = React.useContext(authContext);
  if (auth.authToken && !auth.profile) {
    loadProfile(auth.authToken, auth);
  }
  const userName = auth.profile ? auth.profile.displayName || 'No Name' : 'No Profile';

  return (
    <SesameLogin>
      <div>
        <a >{userName}</a>
        <div className="uk-background-secondary uk-light" uk-dropdown="mode: click">
          <Logout />
        </div>
      </div>
    </SesameLogin>
  );
};

export default UserProfile;
