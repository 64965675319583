import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import LoginLink from '../components/LoginLink';
import { authContext } from '../contexts/AuthContext';

const hideDropDown = (e: React.MouseEvent<HTMLLIElement>) => {
  const dropDown: any = document.getElementById('adminDropdown');
  if (dropDown) {
    (window as any).UIkit.dropdown(dropDown).hide(0);
  }
};

const PrimaryNav = (props: React.HTMLAttributes<HTMLElement>) => {
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;
  return (
    <Fragment>
      <nav className="uk-navbar-container uk-flex-column uk-flex-top uk-padding-none" data-uk-navbar data-uk-toggle="media: @s; cls: uk-flex-row uk-flex-top; mode: media">
        {
          // @ts-ignore -->
          <button type="button" data-uk-toggle="target: .navbar-collapse; cls: hidden-up-to-s uk-padding-remove"
            className="uk-navbar-toggle uk-hidden@s" data-uk-navbar-toggle-icon></button>
        }
        <div className="navbar-collapse hidden-up-to-s">
          <div className="uk-navbar-left" >
            {
              <ul data-uk-toggle="media: @s; cls: uk-navbar-nav uk-padding-remove; mode: media" className="uk-nav uk-nav-primary uk-padding-small">
                {!authToken &&
                  <li>
                    <LoginLink>Log In</LoginLink>
                  </li>
                }
                {authToken &&
                  <li className="uk-parent" >
                    <NavLink to="/users" activeClassName="uk-active">Users</NavLink>
                  </li>
                }
                {authToken &&
                  <li className="uk-parent" >
                    <NavLink to="/sessions" activeClassName="uk-active">Sessions</NavLink>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      </nav>

    </Fragment >
  );
};

export default PrimaryNav;
