import React, { PropsWithChildren, useEffect } from 'react';
import { Cell, FilterProps, TableOptions, useFilters, useSortBy, useTable } from 'react-table';

import TableToolBar from './TableToolBar';

interface IUserTableProps<T extends object = {}> extends TableOptions<T> {
  columns: any;
  data: any;
}

interface IUserTableFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

export default function Table<T extends object>(props: PropsWithChildren<IUserTableProps<T>>) {

  const { name, columns, onAdd, onEdit, onDelete } = props;

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  };
  const filterTypes = {
    text: (filterRows: any, id: number, filterValue: string) => {
      return filterRows.filter((row: any) => {
        const rowValue = row.values[id];
        const matchString = new RegExp(String(filterValue), 'i');
        return rowValue !== undefined
          ? String(rowValue).match(matchString)
          : true;
      });
    },
  };

  const instance = useTable<T>(
    {
      ...props,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = instance;
  /*
    useEffect(() => {
      setValue(filterValue || '')
    }, [filterValue])
    */

  return (
    <div className="uk-margin-top">
      <TableToolBar instance={instance} {...{ onAdd, onEdit, onDelete }} />
      <table {...getTableProps()} className="uk-table uk-padding-remove-top uk-margin-remove-top" >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted ?
                      column.isSortedDesc ?
                        <span uk-icon="triangle-down" /> : <span uk-icon="triangle-up" />
                      : ''
                    }
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="uk-table-striped" {...getTableBodyProps}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => {
                  return (
                    <td className="nowrap">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function DefaultColumnFilter<T extends object>({
  column: { id, index, filterValue, setFilter, render, parent },
}: FilterProps<T>) {
  const [value, setValue] = React.useState(filterValue || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setFilter(event.target.value || undefined);
    event.preventDefault();
  };

  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || '');
  }, [filterValue]);
  const firstIndex = !(parent && parent.index);

  return (
    <>
      <input
        className="uk-input"
        name={id}
        value={value}
        autoFocus={index === 0 && firstIndex}
        onChange={handleChange}
        onBlur={(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    </>
  );
}
