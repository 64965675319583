import React, { MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { TableInstance } from 'react-table';
import { TableMouseEventHandler } from '../../types/react-table-config';
import AddUser from '../AddUser';
import { FilterPage } from './FilterPage';

interface ITableToolBar<T extends object> {
  instance: TableInstance<T>;
  onAdd?: TableMouseEventHandler;
  onDelete?: TableMouseEventHandler;
  onEdit?: TableMouseEventHandler;
}

export default function TableToolBar<T extends object>({
  instance,
  onAdd,
  onDelete,
  onEdit,
}: PropsWithChildren<ITableToolBar<T>>): ReactElement | null {

  const [filterOpen, setFilterOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const handleClose = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const handleAddClose = useCallback(() => {
    setAddOpen(false);
  }, []);

  return <div>
    <div>
      <span className="uk-align-left">
        <a href="" className="uk-icon-link" uk-icon="settings"
          onClick={(e) => { e.preventDefault(); setFilterOpen(!filterOpen); }} />
      </span>
      {onAdd &&
        <span className="uk-align-right">
          <a href="" className="uk-icon-link" uk-icon="plus"
            onClick={(e) => { e.preventDefault(); setAddOpen(!addOpen); }} />
        </span>
      }
    </div>
    <div className="uk-align-left">
      <FilterPage<T> instance={instance} onClose={handleClose} show={filterOpen} />
    </div>
    {onAdd &&
      <div className="uk-align-right">
        {onAdd({ onClose: handleAddClose, show: addOpen })}
      </div>
    }
  </div>;
}
