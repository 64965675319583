import React, { FormEvent, useCallback, useContext, useState } from 'react';
import Alert, { AlertTypes } from '../components/AlertDisplay';
import { authContext } from '../contexts/AuthContext';
import { addSession } from '../util/UserUtil';

interface IFilterPage<T extends object> {
  anchorEl?: Element;
  onClose: () => void;
  show: boolean;
}

interface IFormData {
  userIdentity: string;
  expires: string;
}

export default function AddSession<T extends object>({ onClose, show }: IFilterPage<T>): React.ReactElement {

  const auth = useContext(authContext);
  const [session, setSession] = useState({
    expires: '',
    userIdentity: '',
  });

  const onSubmit = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const expDate = new Date(session.expires);
    console.log('date: ' + expDate);

    if (auth.authToken && session.userIdentity && !isNaN(expDate.getDate())) {
      addSession(auth.authToken, { userIdentity: session.userIdentity, expires: expDate }).then((res: string) => {
        Alert(AlertTypes.SUCCESS, 'Session Created');
        onSubmitSuccess(e);
      }).catch((err) => {
        console.log('create session error: ' + err);
        Alert(AlertTypes.ERROR, err.message);
      });
    }
  };

  const onSubmitSuccess = useCallback((e: FormEvent<HTMLButtonElement>) => {
    onClose();
  },
    [onClose],
  );

  if (!show) {
    return <div />;
  }
  return (
    <div>
      <h4>Add Session</h4>
      <span className="uk-margin">User Identity:</span>
      <br />
      <input
        className="uk-input"
        name="userIdentity"
        placeholder="Identity"
        value={session.userIdentity}
        onChange={(e) => { setSession({ ...session, userIdentity: e.target.value }); }} />
      <br />
      <span className="uk-margin">Expires</span>
      <br />
      <input className="uk-input"
        name="expires"
        placeholder="DD/MM/YYYY"
        value={session.expires}
        onChange={(e) => { setSession({ ...session, expires: e.target.value }); }} />
      <br />
      <button className="uk-button uk-button-primary uk-margin"
        disabled={!(session.userIdentity && session.expires && !isNaN(new Date(session.expires).getDate()))}
        onClick={((e) => onSubmit(e))}>Save</button>
    </div >
  );
}
