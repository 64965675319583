import React, { Component, MouseEvent, useEffect, useState } from 'react';
import { authContext } from '../contexts/AuthContext';
import { IUser } from '../types';
import { getUsers } from '../util/UserUtil';
import AddUser from '../widgets/AddUser';
import Table from '../widgets/Table/Table';

import { ToggleFilter, toggleFilter } from '../widgets/Table/filters/toggleFilter';

export default function Users() {
  const [loadState, setLoadState] = useState('NEW');
  const [userList, setUserList] = useState<IUser[]>([]);
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayName',
      },
      {
        Header: 'email',
        accessor: 'email',
        sortable: true,
      },
      {
        Header: 'User Identity',
        accessor: 'userIdentity',
      },
      {
        Filter: ToggleFilter,
        Header: 'Superuser',
        accessor: (row: any) => row.privileges.includes('SUPERUSER') ? 'Y' : '',
        filter: toggleFilter,
        id: 'superuser',
        sortable: true,
      },
      {
        Filter: ToggleFilter,
        Header: 'System Account',
        accessor: (row: any) => row.privileges.includes('SYSTEM') ? 'Y' : '',
        filter: toggleFilter,
        id: 'systemacct',
        sortable: true,
      },
    ],
    [],
  );

  useEffect(() => {
    if (authToken) {
      getUsers(authToken).then((users) => {
        console.log('succeeded');
        setUserList(users);
        setLoadState('READY');
      }).catch((e) => {
        console.log('failed');
        setLoadState('FAILED');
      });
    }
    setLoadState('LOADING');
  }, []);

  if (authToken === undefined || !authToken || loadState === 'FAILED') {
    return (<div className="uk-card uk-padding">Not authorized</div>);
  }

  if (loadState === 'NEW' || loadState === 'LOADING') {
    return <>
      <h2>Users</h2>
      <div className="uk-card uk-padding" data-uk-spinner />
    </>;
  }
  return (
    <div className="uk-margin-left">
      <h2 >Users</h2>
      <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
        <Table columns={columns} data={userList} onAdd={AddUser} />
      </div>
    </div>
  );
}
