import * as React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import AuthContextProvider from './contexts/AuthContext';
import Index from './pages/Index';
import Sessions from './pages/Sessions';
import Users from './pages/Users';
import CompleteLogin from './widgets/CompleteLogin';
import PageHeader from './widgets/pageHeader';
import PrimaryNav from './widgets/PrimaryNav';

export default function App() {
  return (
    <AuthContextProvider>
      <div className="App">
        <BrowserRouter>
          <div>
            <PageHeader />
            <PrimaryNav />
          </div>
          <div>
            <Switch>
              <Route path="/loginConfirm" >
                <CompleteLogin />
              </Route>
              <Route exact path="/">
                <Index />
              </Route>
              <ProtectedRoute path="/users" denyPath="/" component={Users} />
              <ProtectedRoute path="/sessions" denyPath="/" component={Sessions} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </AuthContextProvider>
  );
}
