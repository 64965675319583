import React, { FormEvent, ReactElement, useCallback, useState } from 'react';
import { TableInstance } from 'react-table';

interface IFilterPage<T extends object> {
  instance: TableInstance<T>;
  anchorEl?: Element;
  onClose: () => void;
  show: boolean;
}

export function FilterPage<T extends object>({ instance, onClose, show }: IFilterPage<T>): ReactElement {
  const { allColumns, setAllFilters } = instance;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose],
  );

  const resetFilters = useCallback(() => {
    setAllFilters([]);
  }, [setAllFilters]);

  if (!show) {
    return <></>;
  }
  return (
    <div className="uk-child-width-1-5@m uk-grid-small" data-uk-grid>
      {allColumns
        .filter((it) => it.canFilter)
        .map((column) => (
          <div >
            <div className="uk-card uk-card-body uk-padding-small uk-margkin-small"
              key={column.id} >
              <span className="uk-table-header">{column.render('Header')}</span><br />{column.render('Filter')}
            </div>
          </div>
        ))}
      { /*
      <div>
        <div className="uk-card uk-card-body uk-padding-small uk-margkin-small" key="reset-button">
          <button className="uk-button-icon" uk-icon="minus-circle" onClick={resetFilters} />
        </div>
      </div>
        */}
    </div >
  );
}
