import React from 'react';
// import UIKit from 'uikit';
import SesameLogo from '../components/sesameLogo';
import UserProfile from '../widgets/UserProfile';

// const margin = UIKit.margin('#margin', {})

const PageHeader = (props: React.HTMLAttributes<HTMLElement>) => (
  <div
    className="uk-background-default uk-flex uk-flex-left"
  >
    <div>
      <h2 className="uk-margin-remove-bottom uk-padding-small">
        <SesameLogo />
        <span className="uk-margin-left">
          Sesame User Admin
        </span>
      </h2>
    </div>
    <div className="uk-width-expand">
      <div className="uk-align-left uk-card">
      </div>
    </div>
    <div>
      <div className="uk-card uk-margin-top uk-padding-small uk-align-right uk-valign-center">
        <UserProfile />
      </div>
    </div>
  </div>
);

export default PageHeader;
