import { authContext, IAuthContextInterface } from '../contexts/AuthContext';
import { ISessionRequest, IUser, IUserResponse, IUserSession } from '../types';
import { config } from './../config';
import API from './../util/API';

export async function getUsers(bearerToken: string): Promise<IUser[]> {
  const response = await API.get(bearerToken, `/api/v1/users`);
  return response.data;
}

export async function getUserSessions(bearerToken: string): Promise<IUserSession[]> {
  const response = await API.get(bearerToken, `/api/v1/users/sessions`);
  return response.data;
}

export async function addUser(bearerToken: string, user: IUser): Promise<IUserResponse> {
  const response = await API.post(bearerToken, '/api/v1/users',
    { email: user.email, displayName: user.displayName, username: user.username, });
  return response.data;
}

export async function setUserPrivileges(bearerToken: string,
  userIdentity: string, privileges: string[]): Promise<boolean> {

  const response = await API.post(bearerToken, `/api/v1/users/${userIdentity}/privileges`,
    { privileges });
  return response.data;
}

export async function addSession(bearerToken: string, session: ISessionRequest): Promise<string> {
  const response = await API.post(bearerToken, `/api/v1/users/${session.userIdentity}/session`,
    { expires: session.expires });
  return response.data;
}
