import React, { FormEvent, useCallback, useContext, useState } from 'react';
import Alert, { AlertTypes } from '../components/AlertDisplay';
import { authContext } from '../contexts/AuthContext';
import { IUser, IUserResponse } from '../types/users';
import { addUser, setUserPrivileges } from '../util/UserUtil';

interface IFilterPage<T extends object> {
  anchorEl?: Element;
  onClose: () => void;
  show: boolean;
}

export default function AddUser<T extends object>({ onClose, show }: IFilterPage<T>): React.ReactElement {

  const auth = useContext(authContext);
  const [privs, setPrivs] = useState({ superuser: false, systemAccount: false });
  const [user, setUser] = useState<IUser>({
    displayName: '',
    email: '',
    privileges: [],
    slackId: '',
    userId: 0,
    userIdentity: '',
    username: '',
  });

  const onSubmit = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (auth.authToken && user) {
      addUser(auth.authToken, user).then((res: IUserResponse) => {
        const privileges = [];
        if (privs.superuser) { privileges.push('SUPERUSER'); }
        if (privs.systemAccount) { privileges.push('SYSTEM'); }
        console.log("User created: " + JSON.stringify(res));
        setUserPrivileges(auth.authToken || '-', res.userIdentity, privileges)
          .then(() => {
            Alert(AlertTypes.SUCCESS, 'User Saved');
            onSubmitSuccess(e);
          }).catch((err) => {
            console.log('Add user- save privs error: ' + err);
            Alert(AlertTypes.ERROR, err.message);
          });
      }).catch((err) => {
        console.log('Add user error: ' + err);
        Alert(AlertTypes.ERROR, err.message);
      });
    }
  };

  const onSubmitSuccess = useCallback((e: FormEvent<HTMLButtonElement>) => {
    onClose();
  },
    [onClose],
  );

  const onSubmitCancel = useCallback(
    (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClose();
    },
    [onClose],
  );

  const checkValidInput = () => {
    return (
      user.username &&
      user.displayName &&
      user.email.match(/.+@.+\..+/)
    );
  };

  if (!show) {
    return <div />;
  }
  return (
    <div>
      <h4>Add User</h4>
      <span className="uk-margin">Username</span>
      <br /> <input className="uk-input" placeholder="name" value={user.username}
        onChange={(e) => { setUser({ ...user, username: e.target.value }); }} />
      <br />
      <span className="uk-margin">Display Name</span>
      <br /> <input className="uk-input" placeholder="name" value={user.displayName}
        onChange={(e) => setUser({ ...user, displayName: e.target.value })} />
      <br />
      <span className="uk-margin">email<br /></span>
      <input className="uk-input" placeholder="someone@somewhere.com" value={user.email}
        onChange={(e) => setUser({ ...user, email: e.target.value })} />
      <br />
      <span className="uk-margin">Superuser</span>
      <br />
      <input
        className="uk-checkbox"
        name="superuser"
        type="checkbox"
        checked={privs.superuser}
        onChange={(e) => setPrivs({ ...privs, superuser: e.target.checked })}
      />
      <br />
      <span className="uk-margin">System Account</span>
      <br />
      <input
        className="uk-checkbox"
        name="systemAcct"
        type="checkbox" checked={privs.systemAccount}
        onChange={(e) => setPrivs({ ...privs, systemAccount: e.target.checked })}
      />
      <br />
      <button
        className="uk-button uk-button-primary"
        disabled={!checkValidInput()}
        onClick={((e) => onSubmit(e))}>
        Save
      </button>
    </div>
  );
}
