import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { Component, MouseEvent, useEffect, useState } from 'react';
import { authContext } from '../contexts/AuthContext';
import { IUserSession } from '../types';
import { getUserSessions } from '../util/UserUtil';
import AddSession from '../widgets/AddSession';
import Table from '../widgets/Table/Table';

import { DateFilter, dateFilter } from '../widgets/Table/filters/dateFilter';
import { ToggleFilter, toggleFilter } from '../widgets/Table/filters/toggleFilter';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export default function Sessions() {
  const [loadState, setLoadState] = useState('NEW');
  const [sessionList, setSessionList] = useState<IUserSession[]>([]);
  const auth = React.useContext(authContext);
  const authToken = auth.authToken;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayName',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'User Identity',
        accessor: 'userIdentity',
      },
      {
        Filter: ToggleFilter,
        Header: 'Superuser',
        accessor: (row: any) => row.privileges.includes('SUPERUSER') ? 'Y' : '',
        filter: toggleFilter,
        id: 'superuser',
        sortable: true,
      },
      {
        Filter: ToggleFilter,
        Header: 'System Account',
        accessor: (row: any) => row.privileges.includes('SYSTEM') ? 'Y' : '',
        filter: toggleFilter,
        id: 'systemacct',
        sortable: true,
      },
      {
        Header: 'Auth Token',
        accessor: 'token',
      },
      {
        Cell: ({ cell: { value } }: any) => {
          const d = dayjs(value);
          return (<div>
            <span>{d.toNow(value)}</span>
            <br />
            <span className="uk-text-small uk-text-light" style={{ fontSize: '8px' }}>{value}</span>
          </div >
          );
        },
        Filter: DateFilter,
        Header: 'Expires',
        accessor: (row: any) => dayjs(row.expires).format('MMM D YYYY HH:mm Z'),
        filter: dateFilter,
        id: 'expires',
      },
    ],
    [],
  );

  useEffect(() => {
    if (authToken) {
      getUserSessions(authToken).then((sessions) => {
        setSessionList(sessions);
        setLoadState('READY');
      }).catch(() => {
        setLoadState('FAILED');
      });
    }
    setLoadState('LOADING');
  }, []);

  if (authToken === undefined || !authToken || loadState === 'FAILED') {
    return (<div className="uk-card uk-padding">Not authorized</div>);
  }

  if (loadState === 'NEW' || loadState === 'LOADING') {
    return <>
      <h2>Sessions</h2>
      <div className="uk-card uk-padding" data-uk-spinner />
    </>;
  }
  return (
    <div className="uk-margin-left">
      <h2>Sessions</h2>
      <div className="uk-margin-small uk-margin-left uk-margin-right uk-child-width-1-1" >
        <Table columns={columns} data={sessionList} onAdd={AddSession} />
      </div>
    </div>
  );
}
