import dayjs from 'dayjs';
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React from 'react';
import DatePicker from 'react-date-picker';
import { FilterProps, FilterValue, IdType, Row } from 'react-table';

dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);

export function DateFilter({
  column: { filterValue = [], render, setFilter, preFilteredRows, id },
}: FilterProps<any>) {

  return (
    <span>
      <DatePicker
        value={filterValue[0] ? new Date(filterValue[0]) : undefined}
        onChange={(from: Date | Date[]) => {
          if (from && !Array.isArray(from)) {
            setFilter((old: any[] = []) => [from.toISOString(), old[1]]);
          } else {
            setFilter((old: any[] = []) => [undefined, old[1]]);
          }
        }}
      />
      <br />
      <DatePicker
        value={filterValue[1] ? new Date(filterValue[1]) : undefined}
        onChange={(to: Date | Date[]) => {
          if (to && !Array.isArray(to)) {
            setFilter((old: any[] = []) => [old[0], to.toISOString()]);
          } else {
            setFilter((old: any[] = []) => [old[0], undefined]);
          }
        }}
      />
    </span>
  );
}

export function dateFilter<T extends object>(rows: Array<Row<T>>, id: IdType<T>, filterValue: FilterValue) {
  // filter by start and end dates
  return rows.filter((row) =>
    (!filterValue[0] || dayjs(row.values[id[0]]).isSameOrAfter(filterValue[0], 'day')) &&
    (!filterValue[1] || dayjs(row.values[id[0]]).isSameOrBefore(filterValue[1], 'day'))
  );
}

// Let the table remove the filter if the string is empty
dateFilter.autoRemove = (val: any) => !val;
